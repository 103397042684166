import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { sessionService } from '../../application/session';
import { AppContext, Types } from '../../context';
import { Breakpoints, isServer } from '../../domain/websiteDomain';
import { createArrayOfLength } from '../../libs/utils';
import { ClassFragment } from '../../typescript/generated/codegen';
import { SessionCard, SkeletonSessionCard } from '../sessions/sessionCard';
import { getNumberOfSlidesInView } from './helpers';

export interface CarouselProps {
  id: string;
  query: string;
  responseKey?: string;
  parseQueryResponse?: (sesssions: ClassFragment[]) => ClassFragment[];
  title: string;
  size?: 'small' | 'big';
  hideSessionFromId?: string;
}

const responsive = {
  0: { items: 1 },
  [Breakpoints.SM]: { items: 2 },
  [Breakpoints.LG]: { items: 3 },
  [Breakpoints['2XL']]: { items: 4 },
};

export const CarouselBML: React.FC<CarouselProps> = ({
  id,
  query,
  title,
  size,
  hideSessionFromId,
}) => {
  const { state, dispatch } = useContext(AppContext);

  const carousel = state.carousels[id];
  const isFetching = carousel?.isFetching;
  const sessions = carousel?.data;

  useEffect(() => {
    const fetchData = async () => {
      dispatch({
        type: Types.SET_CAROUSEL_IS_FETCHING,
        payload: id,
      });

      const applicationSessions = sessionService();
      const sessions = await applicationSessions.getClasses(query);

      // if on session detail view, don't show the viewed session in carousel;
      const sessionsFiltered = sessions.filter(
        session => session.id !== hideSessionFromId
      );

      dispatch({
        type: Types.SET_CAROUSEL_DATA,
        payload: { id, data: sessionsFiltered },
      });
    };

    fetchData();
    // Really should include query, carousel don't actually update when query changes.
    // Unfortunately adding query breaks everything, seems to be some recursive loop going on with context
    // Don't have time to fix now
  }, [title, dispatch, state.filters]);

  if (isServer) return null;

  const skeletons = Array.from(Array(6).keys()).map(i => (
    <SkeletonSessionCard key={i} />
  ));

  return (
    <div>
      {isFetching || !carousel || !sessions ? (
        <>
          <div className="flex justify-between px-5 py-4 lg:px-20">
            <CarouselTitle title={title} size={size} />
          </div>

          <AliceCarousel
            items={skeletons}
            responsive={responsive}
            infinite={false}
            disableDotsControls
            disableButtonsControls
          />
        </>
      ) : (
        <LoadedCarousel sessions={sessions} title={title} size={size} />
      )}
    </div>
  );
};

interface LoadedCarouselProps {
  sessions: ClassFragment[];
  title: string;
  size?: 'small' | 'big';
}

export const LoadedCarousel = ({
  sessions,
  title,
  size,
}: LoadedCarouselProps) => {
  const [slides, setSlides] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    let isDragging = false;
    const handleDragStart = (e: SyntheticEvent) => {
      isDragging = true;
      e.preventDefault();
    };

    const handleClickCapture = (e: SyntheticEvent) => {
      if (isDragging) {
        e.preventDefault();
        isDragging = false;
      }
    };
    // if data has been fetched and there are sessions to display
    const sessionsMinimal = sessions.map(session => (
      <div
        data-cy="carousel-slide-item"
        key={session.beid}
        className={`${
          isDragging && 'no-pointer-events'
        } no-select-or-highlight transparent`}
        onDragStartCapture={handleDragStart}
        onClickCapture={handleClickCapture}
        draggable
      >
        <SessionCard session={session} showTeacher />
      </div>
    ));

    const minSlides = getNumberOfSlidesInView(responsive);

    const injectSlidesToMakeFull = (slides: JSX.Element[]) => {
      const numberOfSlidesToInject = minSlides - slides.length;

      return [
        ...slides,
        ...createArrayOfLength(numberOfSlidesToInject).map((_, i) => (
          <div key={`${title}-${i}`} />
        )),
      ];
    };

    const slidesMadeUp =
      sessionsMinimal.length < minSlides
        ? injectSlidesToMakeFull(sessionsMinimal)
        : sessionsMinimal;

    setSlides(slidesMadeUp);

    return () => {
      setSlides([]);
    };
  }, [sessions, title]);

  const syncActiveIndex = ({ item }: { item: number }) => setActiveIndex(item);
  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);
  const nextDisabled = activeIndex > sessions.length - 4;
  const prevDisabled = activeIndex === 0;

  if (sessions.length === 0) return null;

  return (
    <div>
      <div className="flex justify-between px-5 py-4 lg:px-20">
        <CarouselTitle title={title} size={size} />
        <div className="items-center hidden md:flex">
          <ChevronLeftIcon
            className={clsx(
              'h-6 w-6 m-1',
              prevDisabled
                ? 'text-gray-400'
                : 'cursor-pointer hover:text-accent-hover'
            )}
            onClick={() => {
              if (!prevDisabled) slidePrev();
            }}
          />
          <ChevronRightIcon
            className={clsx(
              'h-6 w-6 m-1',
              nextDisabled
                ? 'text-gray-400'
                : 'cursor-pointer hover:text-accent-hover'
            )}
            onClick={() => {
              if (!nextDisabled) slideNext();
            }}
          />
        </div>
      </div>

      <AliceCarousel
        items={slides}
        activeIndex={activeIndex}
        onSlideChanged={syncActiveIndex}
        mouseTracking
        responsive={responsive}
        infinite={false}
        disableDotsControls
        disableButtonsControls
      />
    </div>
  );
};

interface CarouselTitleProps {
  size?: 'small' | 'big';
  title: string;
}

export const CarouselTitle = ({ size, title }: CarouselTitleProps) => {
  return (
    <h3
      className={clsx(
        'font-family-bold',
        size === 'big' ? 'text-5xl' : 'text-3xl'
      )}
    >
      {title}
    </h3>
  );
};
