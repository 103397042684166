import { directCoachService } from '../../application/featureToggle';
import { FeatureToggleList } from '../../domain/featureToggleEnv';

interface FeatureToggleProps {
  featureKey: FeatureToggleList;
  children: React.ReactNode;
}

export const FeatureToggle: React.FC<FeatureToggleProps> = ({
  featureKey,
  children,
}) => {
  const featureToggleApplication = directCoachService();

  if (featureToggleApplication.shouldShowFeature(featureKey)) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};
