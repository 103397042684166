import { ClassFragment } from '../../typescript/generated/codegen';

type Responsive = {
  [key: number]: { items: number };
};

export const getNumberOfSlidesInView = (responsive: Responsive) => {
  if (typeof window === 'undefined') return 3;

  const windowWidth = window.innerWidth;

  const items = Object.entries(responsive)
    .sort((a, b) => {
      const widthA = parseInt(a[0]);
      const widthB = parseInt(b[0]);
      if (widthA < widthB) return -1;
      if (widthA > widthB) return 1;
      return 0;
    })
    .reduce((acc, curItem, index, array) => {
      const curWidth = parseInt(curItem[0]);
      const nextWidth = array[index + 1] ? parseInt(array[index + 1][0]) : null;

      if (!nextWidth && windowWidth > curWidth) {
        acc = curItem[1].items;
      } else if (
        nextWidth &&
        windowWidth > curWidth &&
        windowWidth < nextWidth
      ) {
        acc = curItem[1].items;
      }
      return acc;
    }, 0);

  return items;
};
