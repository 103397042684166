import clsx from 'clsx';
import { useContext, useState } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { IconBaseProps } from 'react-icons/lib';
import { userService } from '../../../application/user';
import { AppContext, Types } from '../../../context';
import useUserWithRedirects from '../../../hooks/useUser';
import { ClassFragment } from '../../../typescript/generated/codegen';

interface FavouriteButtonProps extends IconBaseProps {
  buttonClasses?: string;
  iconClasses?: string;
  session: ClassFragment;
}

export default function FavouriteButton({
  session,
  buttonClasses,
  iconClasses,
  ...iconProps
}: FavouriteButtonProps) {
  const { dispatch } = useContext(AppContext);
  const [savingFavourite, setSavingFavourite] = useState(false);
  const user = useUserWithRedirects();

  if (!user.isLoggedIn) return null;

  const handleFavourite = async (session: ClassFragment) => {
    const applicationUser = userService();

    try {
      if (!savingFavourite) {
        setSavingFavourite(true);
        await applicationUser.saveUserFavouriteVideo(session);
        const u = await applicationUser.fetchUserWithSettingsOrUnauthedUser();

        dispatch({ type: Types.SET_USER, payload: u });
      }
    } catch (err) {
      console.warn('error saving favourites', session.id);
    } finally {
      setSavingFavourite(false);
    }
  };

  return (
    <button
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        handleFavourite(session);
      }}
      className={clsx('focus:outline-none', buttonClasses)}
      aria-label="Favourite Video"
    >
      {user?.settings?.favourited?.some((fav: any) => fav.id === session.id) ? (
        <AiFillHeart {...iconProps} className={iconClasses} />
      ) : (
        <AiOutlineHeart {...iconProps} className={iconClasses} />
      )}
    </button>
  );
}
