import Link from 'next/link';
import React, { useContext } from 'react';
import { IoMdVideocam } from 'react-icons/io';
import { AppContext } from '../../context';
import { getSessionRoute } from '../../domain/class';
import { FeatureToggleList } from '../../domain/featureToggleEnv';
import { getTeacherRoute } from '../../domain/teacher';
import { niceDateAndTime } from '../../libs/time';
import { ClassFragment } from '../../typescript/generated/codegen';
import { FeatureToggle } from '../app/featureToggle';
import { Tag, TagOptions } from '../common/tag';

interface SlideLabelProps {
  session: ClassFragment;
  showTeacher: boolean;
}

const SlideLabel: React.FC<SlideLabelProps> = ({ session, showTeacher }) => {
  const { teacher, beid } = session;
  const { state } = useContext(AppContext);

  if (!teacher || !beid) return null;

  const sessionTag =
    state.directCoach.directCoachNextSessionsByClassTag?.[beid];

  const date = sessionTag && niceDateAndTime(sessionTag.dateRange.startDate);

  // REFACTOR: use smaller components and build up, rather than toggling inside jsx
  return (
    <div className="space-y-1 text-base pt-8px sm:pt-16px sm:text-lg sm:font-family-semibold">
      <Link href={getSessionRoute(session)}>
        <a>
          <p className="hover:text-accent-hover font-family-bold sm:line-clamp-2 line-clamp-1">
            {session.name}
          </p>
        </a>
      </Link>

      {showTeacher && (
        <Link href={getTeacherRoute(teacher)}>
          <a
            data-cy={`slide-teacher-name-label-${teacher.slug}`}
            id={`slide-lable-teacher-name-${teacher.slug}-${beid}`}
            className="hover:text-accent-hover font-family-regular"
          >
            {teacher.displayName}
          </a>
        </Link>
      )}

      <div className="h-8 w-fit" style={{ width: 'fit-content' }}>
        {date && (
          <FeatureToggle featureKey={FeatureToggleList.DirectCoach}>
            <Tag tagType={TagOptions.DirectCoach}>
              <div className="flex items-center gap-2 p-2px">
                <IoMdVideocam size={15} />
                <p className="text-sm">{date}</p>
              </div>
            </Tag>
          </FeatureToggle>
        )}
      </div>
    </div>
  );
};

export default SlideLabel;
