import Image from 'next/image';
import Link from 'next/link';
import React, { useState } from 'react';
import { getSessionRoute } from '../../domain/class';
import { convertSecondsToDurationLabel } from '../../libs/time';
import { ClassFragment } from '../../typescript/generated/codegen';
import FavouriteButton from '../common/favouriteButton/favouriteButton';
import { Tag, TagOptions } from '../common/tag';
import SlideLabel from './sessionLabel';

export interface SessionCardProps {
  session: ClassFragment;
  showTeacher: boolean;
}

export const SessionCard: React.FC<SessionCardProps> = ({
  session,
  showTeacher,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <CardWrapper>
      <Link href={getSessionRoute(session)}>
        <a>
          <div className="relative w-full h-48 text-xs text-white rounded-lg cursor-pointer font-family-semibold sm:text-sm">
            {isLoading && (
              <div
                className={'absolute z-0 rounded-lg skeleton-background'}
                style={{ top: 0, bottom: 0, left: 0, right: 0 }}
              />
            )}
            <Image
              alt={session.image?.title}
              src={session.image?.url || '/og-image.png'}
              layout="fill"
              className="rounded-lg "
              objectFit="cover"
              onLoadingComplete={() => {
                setIsLoading(false);
              }}
            />
            <div className="absolute inset-0 transition duration-300 bg-black rounded-lg bg-opacity-10 hover:bg-opacity-0" />

            <div className="absolute top-0 left-0 flex flex-row items-center p-8px lg:p-14px space-x-8px">
              {[session.classification, session.type]
                .filter(Boolean)
                .map(tag => (
                  <Tag
                    tagType={TagOptions.Default}
                    key={`classification-${tag}`}
                  >
                    {tag}
                  </Tag>
                ))}
            </div>

            <FavouriteButton
              buttonClasses="absolute top-2 right-2 focus:outline-none"
              iconClasses="w-7 h-7"
              session={session}
            />

            <ul className="absolute bottom-0 left-0 right-0 flex flex-row items-center justify-between p-8px lg:p-14px">
              <li>
                <Tag tagType={TagOptions.Default} key="level">
                  {session.level}
                </Tag>
              </li>
              <li>
                <Tag tagType={TagOptions.Default} key="seconds">
                  {session.videoseconds &&
                    convertSecondsToDurationLabel(session.videoseconds)}
                </Tag>
              </li>
            </ul>
          </div>
        </a>
      </Link>

      <SlideLabel session={session} showTeacher={showTeacher} />
    </CardWrapper>
  );
};

export const SkeletonSessionCard: React.FC = () => {
  return (
    <CardWrapper>
      <div className="relative h-48 rounded-lg skeleton-background"></div>

      <div className="flex flex-col pt-2 space-y-1 sm:pt-4">
        <div className="w-3/4 h-4 rounded-md sm:h-5 skeleton-background"></div>
        <div className="w-40 h-4 rounded-md sm:h-5 skeleton-background" />
      </div>
    </CardWrapper>
  );
};

interface CardWrapperProps {
  children: React.ReactNode;
}

export const CardWrapper = ({ children }: CardWrapperProps) => {
  return <div className="w-full pr-4 h-72">{children}</div>;
};

export default SessionCard;
