export enum TagOptions {
  Default,
  DirectCoach,
  Pearl,
  Info,
}
interface TagProps {
  children: React.ReactNode;
  tagType: TagOptions;
}
export const Tag: React.FC<TagProps & React.HTMLProps<HTMLDivElement>> = ({
  tagType,
  children,
  ...props
}) => {
  const tagOptions = {
    [TagOptions.Default]:
      'z-10 px-2 py-1 text-sm text-white bg-black rounded opacity-80 font-family-semibold',
    [TagOptions.DirectCoach]:
      'px-2 py-1 text-sm text-white bg-blue rounded font-family-semibold',
    [TagOptions.Pearl]:
      'px-6 py-2 border rounded-full font-family-semibold border-ice',
    [TagOptions.Info]:
      'px-2 py-1 text-white text-sm rounded font-family-semibold bg-gray',
  };

  return (
    <div className={tagOptions[tagType]} {...props}>
      {children}
    </div>
  );
};
