import React from 'react';
import useUserWithRedirects from '../../hooks/useUser';
import { secondsToMinutesRounded } from '../../libs/time';
import { ClassFragment } from '../../typescript/generated/codegen';
import FavouriteButton from '../common/favouriteButton/favouriteButton';
import Teacher from './teacher';

interface SessionDetailsProps {
  session: ClassFragment;
}

const SessionDetails: React.FC<SessionDetailsProps> = ({ session }) => {
  const { isLoggedIn } = useUserWithRedirects();

  const { classification, level, videoseconds } = session;

  const minutesRounded = videoseconds && secondsToMinutesRounded(videoseconds);

  if (!session.teacher) return null;

  return (
    <div>
      <div className="mb-2 text-5xl font-semibold pointer-events-auto sm:text-6xl lg:text-8xl md:mb-6">
        {session.name}
      </div>
      <div className="flex flex-col-reverse space-y-4 space-y-reverse text-sm md:flex-row md:items-center md:space-y-0 md:space-x-8 sm:text-base md:text-xl">
        <div className="pointer-events-auto w-content">
          <Teacher teacher={session.teacher} />
        </div>
        <div className="z-30 flex divide-x pointer-events-auto space-8 text-md align-center divide-subheading">
          <p className="px-2">{classification}</p>
          <p className="px-2">{level}</p>
          {minutesRounded && <p className="px-2">{minutesRounded} minutes</p>}
          {isLoggedIn && (
            <FavouriteButton
              iconClasses="w-5 h-5 md:w-6 md:h-6"
              session={session}
              buttonClasses="px-2"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionDetails;
