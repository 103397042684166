import Link from 'next/link';
import React from 'react';
import { routes } from '../../domain/websiteDomain';
import { TeacherFragment } from '../../typescript/generated/codegen';
import Avatar from '../teacher/avatar';

interface TeacherProps {
  teacher: TeacherFragment;
}

const Teacher: React.FC<TeacherProps> = ({ teacher }) => {
  return (
    <Link
      href={{
        pathname: routes.TEACHER_PROFILE,
        query: { teacherName: teacher.slug },
      }}
    >
      <a>
        <div className="flex items-center space-x-3 transition-colors cursor-pointer md:space-x-4 hover:text-accent-hover">
          <Avatar teacher={teacher} />
          <span>
            {teacher.firstName} {teacher.lastName}
          </span>
        </div>
      </a>
    </Link>
  );
};

export default Teacher;
